
























































































































































































































import { promiseHelper } from '@/helpers/promise-helper'
import { Observer } from 'mobx-vue'
import { Component, Vue } from 'vue-property-decorator'
import { saleViewModel } from '../viewmodels/market-viewmodel'
import { walletStore } from '@/stores/wallet-store'
import { MarketKeyInfo } from '@/blockchainHandlers/ido-contract-interface'
import { PoolStore } from '@/modules/ido/stores/pool-store'

@Observer
@Component({
  components: {
    HoverImage: () => import('./hover-image.vue'),
  },
})
export default class MarketSaleDialog extends Vue {
  vm = saleViewModel
  dialog = false
  isValidate = false
  isChoosingNFT = false
  isNFTSelected = false
  error = ''

  headers = [
    { text: 'Project', value: 'poolStore.projectName', sortable: false },
    { text: 'ID', value: 'keyInfo.id', sortable: false },
    { text: 'Remaining', value: 'keyInfo.remain', sortable: false },
    { text: '', value: 'select', sortable: false },
  ]

  resolve?: (any) => void

  async openFromMarket() {
    this.vm.resetInput()
    this.dialog = true
    await promiseHelper.delay(100)
    return new Promise((r) => (this.resolve = r))
  }
  async open(keyInfo: MarketKeyInfo, poolStore: PoolStore) {
    this.vm.resetInput()
    this.dialog = true
    this.selectNftCard({
      keyInfo,
      poolStore,
      wallet: walletStore.account,
    })
    await promiseHelper.delay(100)
    return new Promise((r) => (this.resolve = r))
  }
  async cancel(result = false) {
    this.resolve && this.resolve(result)
    this.dialog = false
    await promiseHelper.delay(100)
    this.error = ''
  }

  clearSelected() {
    this.vm.selectedNFT = null
  }

  toChooseNFTCard() {
    if (this.vm.approving || this.vm.orderCreating) return
    this.isChoosingNFT = true
  }

  toNFTSaleForm() {
    this.isChoosingNFT = false
  }

  selectNftCard(item) {
    this.isNFTSelected = true
    this.isChoosingNFT = false
    this.vm.resetInput()
    this.vm.selectNftCard(item)
  }
}
